import React, {Component} from 'react';
import {connect} from "react-redux";
import { FormControl, Container, Button, Modal, Table } from 'react-bootstrap';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import orderBy from "lodash/orderBy";
import triggerNotification from 'actions/api/employees/trigger-notification';

import { ReactComponent as AscIcon } from "assets/icons/sort-ascending-bold.svg";
import { ReactComponent as DescIcon } from "assets/icons/sort-descending-bold.svg";
import { ReactComponent as NeutralIcon } from "assets/icons/sort-neutre-bold.svg";

import listUsers from 'actions/api/users/list';
import {displayNotification} from 'actions/notifications';
import { DEFAULT_NOTIFICATION_TIMEOUT, TYPE_SUCCESS, TYPE_ERROR } from 'constants/notifications';

class EmployeesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUserFirstName: '',
            newUserLastName: '',
            newUserEmail: '',
            validEmail: false,
            invalidEmailMessage: false,
            inviteEmployees: [],
            employeesArr: props.employees || [],
            orderDirection: '',
            orderByLabel: '',
        };
        this.filterInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.employees, this.props.employees)) {
            this.setState({
                employeesArr: this.props.employees,
            });
        }
    }

    toggleEmployee = (e, employee) => {
        const { inviteEmployees } = this.state;

        let cloneEmployees = cloneDeep(inviteEmployees);
        let findEmployeeIndex = findIndex(inviteEmployees, item => {
            return item.employee_id === employee.employee_id;
        });

        if (findEmployeeIndex > -1) {
            remove(cloneEmployees, item => item.employee_id === employee.employee_id);
        } else {
            cloneEmployees.push(employee);
        }

        this.setState({inviteEmployees: cloneEmployees});
    }

    sendInvite = () => {
        const { inviteEmployees } = this.state;
        const { licenseeObj, togglePopUp, isAgent } = this.props;

        let params = {
            organisationId: isAgent ? licenseeObj.agent_organisation_id : licenseeObj.licensor_organisation_id,
            emails: map(inviteEmployees, 'email'),
            notification: isAgent ? 'licensor_linked_to_agent' : 'licensee_linked_to_licensor',
        };

        triggerNotification(params).then((resp) => {
            this.props.displayNotification({
                message: 'Email invites sent successfully.',
                type: TYPE_SUCCESS,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT,
            });

            this.setState({
                inviteEmployees: [],
            });

            togglePopUp();
        }, () => {
            this.props.displayNotification({
                message: 'Oops, something went wrong. Please try again.',
                type: TYPE_ERROR,
                timeout: DEFAULT_NOTIFICATION_TIMEOUT,
            });
        });
    }

    filterEmployees = (value) => {
        const { employees } = this.props;

        let cloneEmployees = cloneDeep(employees);

        this.setState({
            employeesArr: filter(cloneEmployees, e => !!includes(e.email, value)),
        });
    }

    clearFilter = () => {
        if (this.filterInputRef.current) {
            this.filterInputRef.current.value = '';
        }
        this.filterEmployees('');
    }

    toggleOrderList(orderNewLabel) {
        const { orderByLabel, orderDirection } = this.state;

        if (orderByLabel !== orderNewLabel) {
            this.setState({ orderByLabel: orderNewLabel, orderDirection: 'asc' });
        } else {
            let newOrderDirection = orderDirection === 'asc' ? 'desc' : (orderDirection === '' ? 'asc' : '');
            this.setState({ orderDirection: newOrderDirection });
        }
    }

    onHideModal() {
        const { togglePopUp } = this.props;

        togglePopUp();

        setTimeout(() => {
            this.clearFilter(); // Clear the filter when hiding the modal
            this.setState({inviteEmployees: []});
        }, 1000);
    }

    render() {
        const { employeesArr, orderDirection, orderByLabel, inviteEmployees } = this.state;
        const { show = false, licenseeObj, isAgent, selectedOrg } = this.props;

        return (
            <div className="x-add-org-modal">
                <Modal show={show} onHide={() => this.onHideModal()} className='employees-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>{isAgent ? selectedOrg && selectedOrg.name || '' : licenseeObj.licensee_organisation_name} - Employees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <div>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                    <tr>
                                        <th className='checkbox-th'></th>
                                        <th>
                                            <div className='email-wrapper'>
                                                <span>Email</span>

                                                <div className={`filter-input-box`}>
                                                <FormControl
                                                    type="text"
                                                    autoFocus={true}
                                                    placeholder="Filter"
                                                    className="x-filter-bar-search-box email-filter-box"
                                                    onChange={(event) => this.filterEmployees(event.target.value || '')}
                                                    ref={this.filterInputRef}
                                                />
                                                <span
                                                    className={`filter-cleaner`}
                                                    onClick={() => this.clearFilter()}>✕</span>
                                            </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div className={`table-head-wrapper head-with-sort`}>
                                                <span>First Name</span>

                                                <i className={'order-icon'}
                                                   onClick={() => {
                                                       this.toggleOrderList('first_name');
                                                   }}>{
                                                    'first_name' === orderByLabel ? (<>
                                                            {orderDirection === 'asc' ? (<AscIcon />) :
                                                                orderDirection === 'desc' ? (<DescIcon />) :
                                                                    (<NeutralIcon />)}</>) :
                                                        (<NeutralIcon />)
                                                }

                                                </i>
                                            </div>
                                        </th>
                                        <th>
                                            <div className={`table-head-wrapper head-with-sort`}>
                                                <span>Last Name</span>

                                                <i className={'order-icon'}
                                                   onClick={() => {
                                                       this.toggleOrderList('last_name');
                                                   }}>{
                                                    'last_name' === orderByLabel ? (<>
                                                            {orderDirection === 'asc' ? (<AscIcon />) :
                                                                orderDirection === 'desc' ? (<DescIcon />) :
                                                                    (<NeutralIcon />)}</>) :
                                                        (<NeutralIcon />)
                                                }
                                                </i>
                                            </div>
                                        </th>
                                        <th>Employee ID</th>
                                        <th>User ID</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orderBy(employeesArr,
                                            [item => (item[orderByLabel] || '').toLowerCase()],
                                            [orderDirection]).map(employee => {
                                            return <tr key={employee.employee_id}>
                                                <td className='checkbox-th'>
                                                    <input onChange={(e) => this.toggleEmployee(e, employee)} type="checkbox" />
                                                </td>
                                                <td><div className='email-td'>{employee.email}</div></td>
                                                <td>{employee.first_name}</td>
                                                <td>{employee.last_name}</td>
                                                <td>{employee.employee_id}</td>
                                                <td>{employee.user_id}</td>
                                            </tr>;
                                        })
                                    }
                                    </tbody>

                                    { !employeesArr.length && (
                                        <td className="no-data" colSpan={6}>There is no data to show right now.</td>
                                    )}
                                </Table>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.onHideModal()}>
                            Close
                        </Button>
                        <Button variant="primary"
                                disabled={!inviteEmployees.length}
                                onClick={() => this.sendInvite()}>
                            Send Notification
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default connect(
    null,
    {listUsers, displayNotification}
)(EmployeesModal);
